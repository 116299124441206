<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" @close="cancel"
               :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <div style="width: 100%;display: flex;align-items: flex-start">
            <el-form ref="form" :model="form" :rules="rules" class="x-edit">
                <el-divider content-position="left">常规信息</el-divider>
                <el-form-item label="商品名称" prop="shangPinMC" class="w50">
                    <el-input @change="toPinYinMa" v-model="form.shangPinMC" size="small" maxlength="36" show-word-limit/>
                </el-form-item>
                <el-form-item label="商品货号" prop="shangPinHuoHao" class="w50">
                    <el-input v-model="form.shangPinHuoHao" size="small" maxlength="36" show-word-limit/>
                    <el-button style="margin-left: 10px" type="text" size="small" @click="getItemNumber">生成货号</el-button>
                    <el-tooltip :content="huoHaoContent" placement="top">
                        <el-button type="text" icon="el-icon-warning-outline"></el-button>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="商品条码" prop="shangPinTiaoMa" class="line">
                    <el-input class="w75" v-model="form.shangPinTiaoMa" size="small" maxlength="36" show-word-limit/>
                    <el-button style="margin-left: 10px" type="text" size="small" @click="getBarCode">生成条码</el-button>
                </el-form-item>
                <el-form-item label="商品类别" prop="shangPinLeiBie" class="line" size="small">
                    <el-cascader style="width: 100%" @change="leiBieChange" v-model="form.shangPinLeiBie" :options="fenLeiOptions"
                                 :props="fenLeiProps">
                    </el-cascader>
                </el-form-item>
                <el-form-item v-if="!isDuoGuiGe" label="商品进价" prop="shangPinJinJia" class="w50">
                    <el-input-number v-if="!isUpdate" style="width: 100%" v-model="form.shangPinJinJia"
                                     size="small" maxlength="8" show-word-limit
                                     :precision="2" :step="0.01"/>
                    <span v-else>{{form.shangPinJinJia}}</span>
                </el-form-item>
                <el-form-item v-if="!isDuoGuiGe" label="店内零售价" prop="dianNeiLSJ" class="w50" :rules="this.form.shiFuDianNeiChuShou=='true' ? rules.dianNeiLSJ:[]">
                    <el-input-number style="width: 100%" v-model="form.dianNeiLSJ"
                                     size="small" maxlength="8" show-word-limit
                                     :precision="2" :step="0.01"/>
                </el-form-item>
                <el-form-item label="商品品牌" prop="shangPinPinPai" class="w50">
                    <el-select @change="pinPaiChange" v-model="form.shangPinPinPai" size="small" filterable placeholder="请选择">
                        <el-option v-for="item in pinPaiOptions" :key="item.id" :label="item.pinPaiMC" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品产地" prop="shangPinChanDi" class="w50">
                    <el-input v-model="form.shangPinChanDi" size="small" maxlength="36" show-word-limit/>
                </el-form-item>
                <el-form-item label="商品单位" prop="shangPinDW" class="w50" :rules="this.form.shiFuChengZhong=='false' ? rules.shangPinDW:rules.shangPinDWRequired">
                    <el-select v-model="form.shangPinDW" size="small" filterable placeholder="请选择">
                        <el-option v-for="item in danWeiOptions" :key="item.danWeiMC" :label="item.danWeiMC" :value="item.danWeiMC">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="供货商" prop="gongHuoShang" class="w50">
                    <el-select @change="gongHuoShangChange" v-model="form.gongHuoShang" size="small" filterable placeholder="请选择">
                        <el-option v-for="item in gongHuoShangOptions" :key="item.id" :label="item.gongHuoShangMC" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="库存" prop="shangPinKuCun" class="w50">
                    <el-input-number :disabled="isDuoGuiGe" @change="kuCunChange" v-if="!isUpdate" v-model="form.shangPinKuCun" size="small" :min="0" :max="1000000"></el-input-number>
                    <span v-else>{{form.shangPinKuCun}}</span>
                </el-form-item>
                <el-form-item label="库存超量" prop="shangPinChaoLiang" class="w50">
                    <el-input-number v-model="form.shangPinChaoLiang" size="small" :min="0" :max="1000000"></el-input-number>
                </el-form-item>
                <el-form-item label="库存预警" prop="kuCunYuJing" class="w50">
                    <el-input-number v-model="form.kuCunYuJing" size="small" :min="0" :max="1000000"></el-input-number>
                </el-form-item>
                <el-form-item label="商品排序" prop="shangPinPaiXu" class="w50">
                    <el-input-number style="width: 80%" size="small" v-model="form.shangPinPaiXu" :min="1"
                                     :max="1000"></el-input-number>
                    <el-tooltip style="margin-left: 10px" :content="paiXuContent" placement="top">
                        <el-button size="medium" type="text" icon="el-icon-question"></el-button>
                    </el-tooltip>
                </el-form-item>
                <el-divider content-position="left">称重设置</el-divider>
                <el-form-item label="是否称重" prop="shiFuChengZhong" class="w50">
                    <x-switch :switchDisabled="defaultAttribute.chengZhongDisabled" v-model="form.shiFuChengZhong" dictType="T_OPEN_OR_CLOSE" @change="chengZhongChange"/>
                    <el-tooltip style="margin-left: 10px" :content="chengZhongContent" placement="top">
                        <el-button size="medium" type="text" icon="el-icon-question"></el-button>
                    </el-tooltip>
                </el-form-item>
                <el-divider content-position="left">出售限制</el-divider>
                <el-form-item label="商品状态" prop="shangPinZT">
                    <x-switch v-model="form.shangPinZT" dictType="T_SHANG_PIN_STATUS"/>
                </el-form-item>
                <el-form-item label="是否店内出售" prop="shiFuDianNeiChuShou">
                    <x-switch v-model="form.shiFuDianNeiChuShou" dictType="T_OPEN_OR_CLOSE"/>
                </el-form-item>
                <el-form-item v-if="defaultAttribute.menDianShangPinShow" label="是否店内常用" prop="shiFuDianNeiChangYong">
                    <x-switch v-model="form.shiFuDianNeiChangYong" dictType="T_OPEN_OR_CLOSE"/>
                </el-form-item>
                <el-form-item v-if="defaultAttribute.menDianShangPinShow" label="是否允许拆分" prop="shiFuYunXuChaiFen">
                    <x-switch v-model="form.shiFuYunXuChaiFen" dictType="T_OPEN_OR_CLOSE"/>
                    <el-tooltip style="margin-left: 10px" :content="chaiFenContent" placement="top">
                        <el-button size="medium" type="text" icon="el-icon-question"></el-button>
                    </el-tooltip>
                </el-form-item>
                <el-form-item v-if="defaultAttribute.menDianShangPinShow" label="是否允许退款" prop="shiFuYunXuTuiKuan">
                    <x-switch v-model="form.shiFuYunXuTuiKuan" dictType="T_OPEN_OR_CLOSE"/>
                    <el-tooltip style="margin-left: 10px" :content="tuiKuanContent" placement="top">
                        <el-button size="medium" type="text" icon="el-icon-question"></el-button>
                    </el-tooltip>
                </el-form-item>
                <el-form-item v-if="defaultAttribute.menDianShangPinShow" label="是否允许换货" prop="shiFuYunXuHuanHuo">
                    <x-switch v-model="form.shiFuYunXuHuanHuo" dictType="T_OPEN_OR_CLOSE"/>
                    <el-tooltip style="margin-left: 10px" :content="huanHuoContent" placement="top">
                        <el-button size="medium" type="text" icon="el-icon-question"></el-button>
                    </el-tooltip>
                </el-form-item>
                <el-divider content-position="left">会员优惠</el-divider>
                <el-form-item label="会员价" class="line">
                    <el-form-item v-if="!isDuoGuiGe" prop="huiYuanJia" class="newItem" :rules="hyjIsOpen?rules.requiredHuiYuanJia:rules.huiYuanJia">
                        <el-input type="number"  v-model="form.huiYuanJia"
                                  placeholder="请输入会员价" size="small" maxlength="8" show-word-limit>
                            <el-button size="small" slot="prepend">￥</el-button>
                        </el-input>
                    </el-form-item>
                    <span v-if="!isDuoGuiGe && isJiYuShangPin">+</span>
                    <el-form-item v-if="!isDuoGuiGe && isJiYuShangPin" prop="huiYuanJiaJF" class="newItem" :rules="hyjIsOpen?rules.requiredHuiYuanJiaJF:rules.huiYuanJiaJF">
                        <el-input type="number" v-model="form.huiYuanJiaJF" placeholder="请输入积分" size="small">
                            <el-button size="small" slot="prepend" icon="el-icon-coin"></el-button>
                        </el-input>
                    </el-form-item>
                    <x-switch v-model="form.huiYuanJiaZT" dictType="T_OPEN_OR_CLOSE"/>
                </el-form-item>
                <el-form-item  label="PLUS会员价" class="line">
                    <el-form-item v-if="!isDuoGuiGe" prop="plusHuiYuanJia" class="newItem" :rules="plusHyjIsOpen?rules.requiredHuiYuanJia:rules.huiYuanJia">
                        <el-input type="number"  v-model="form.plusHuiYuanJia" placeholder="请输入PLUS会员价" size="small">
                            <el-button size="small" slot="prepend">￥</el-button>
                        </el-input>
                    </el-form-item>
                    <span v-if="!isDuoGuiGe && isJiYuShangPin">+</span>
                    <el-form-item v-if="!isDuoGuiGe && isJiYuShangPin" prop="plusHuiYuanJiaJF" class="newItem" :rules="plusHyjIsOpen?rules.requiredHuiYuanJiaJF:rules.huiYuanJiaJF">
                        <el-input type="number"  v-model="form.plusHuiYuanJiaJF" placeholder="请输入积分" size="small">
                            <el-button size="small" slot="prepend" icon="el-icon-coin"></el-button>
                        </el-input>
                    </el-form-item>
                    <x-switch v-model="form.plusHuiYuanJiaZT" dictType="T_OPEN_OR_CLOSE"/>
                </el-form-item>
                <el-divider content-position="left">多规格设置</el-divider>
                <el-form-item label="是否多规格" prop="shiFuDuoGuiGe">
                    <x-switch @change="guiGeChange" :switchDisabled="defaultAttribute.guiGeDisabled" v-model="form.shiFuDuoGuiGe" dictType="T_OPEN_OR_CLOSE"/>
                    <el-tooltip style="margin-left: 10px" :content="guiGeContent" placement="top">
                        <el-button size="medium" type="text" icon="el-icon-question"></el-button>
                    </el-tooltip>
                </el-form-item>
                <el-form-item v-if="isDuoGuiGe" label="自动编号" prop="ziDongBH">
                    <x-switch @change="bianHaoChange" :switchDisabled="defaultAttribute.guiGeDisabled" v-model="form.ziDongBH" dictType="T_OPEN_OR_CLOSE"/>
                    <el-tooltip style="margin-left: 10px" :content="ziDongBianHaoContent" placement="top">
                        <el-button size="medium" type="text" icon="el-icon-question"></el-button>
                    </el-tooltip>
                </el-form-item>
                <el-form-item v-if="isDuoGuiGe" label="自动生成条码" prop="ziDongShengChengTiaoMa">
                    <x-switch @change="tiaoMaChange" :switchDisabled="defaultAttribute.guiGeDisabled" v-model="form.ziDongShengChengTiaoMa" dictType="T_OPEN_OR_CLOSE"/>
                    <el-tooltip style="margin-left: 10px" :content="ziDongBianMaContent" placement="top">
                        <el-button size="medium" type="text" icon="el-icon-question"></el-button>
                    </el-tooltip>
                </el-form-item>
                <el-form-item v-if="isDuoGuiGe" label="规格设置" class="line">
                    <el-select :disabled="defaultAttribute.guiGeDisabled" class="w25" size="small" @change="guiGeSelectChange" v-model="form.dingJiGuiGe">
                        <el-option v-for="item in dingJiGuiGeOptions" :key="item.id" :label="item.guiGeMC"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select style="margin-left: 10px" size="small" multiple @change="lowerGuiGeChange"
                               v-model="guiGeCheckList">
                        <el-option v-for="item in lowerGuiGeOptions" :key="item.id" :label="item.guiGeMC"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="isDuoGuiGe" label="批量设置" class="line">
                    <el-select class="w25" v-model="batchSetSelectValue" size="small">
                        <el-option v-for="item in batchSetValueOptions" :key="item.value" :label="item.label"
                                   :value="item.value" :disabled="item.disabled">
                        </el-option>
                    </el-select>
                    <el-input-number style="margin-left: 10px" class="w25"
                                     v-if="batchSetSelectValue==1 || batchSetSelectValue==2" v-model="batchSetValue"
                                     size="small" maxlength="8" show-word-limit
                                     :precision="2" :step="0.01"/>
                    <el-input-number style="margin-left: 10px" class="w25" v-if="batchSetSelectValue==3"
                                     v-model="batchSetValue" size="small" :min="0" :max="1000000"></el-input-number>
                    <el-button style="margin-left: 10px" size="small" type="primary" @click="batchSetFunction">确认
                    </el-button>
                </el-form-item>
                <el-form-item v-if="isDuoGuiGe" label="规格列表" class="line">
                    <div style="width: 100%">
                        <el-table v-if="isDuoGuiGe" :data="form.guiGeShangPinList" border style="width: 830px;">
                            <el-table-column label="序号" type="index" width="50" fixed></el-table-column>
                            <el-table-column width="70px">
                                <template slot="header" slot-scope="scope">
                                    <div>
                                        <span>状态</span>
                                        <el-tooltip :content="guiGeStatusContent" placement="top">
                                            <el-button size="medium" type="text" icon="el-icon-question"></el-button>
                                        </el-tooltip>
                                    </div>
                                    <div>
                                        <el-switch @change="tableAllStatusChange" v-model="tableAllStatus"
                                                   active-value="true" inactive-value="false"></el-switch>
                                    </div>
                                </template>
                                <template slot-scope="scope">
                                    <el-switch v-model="scope.row.shangPinZT" active-value="true" inactive-value="false"></el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column width="110px">
                                <template slot="header" slot-scope="scope">
                                    <div>规格名称</div>
                                </template>
                                <template slot-scope="scope">
                                    {{scope.row.ciJiGuiGeMC}}
                                </template>
                            </el-table-column>
                            <el-table-column width="110px">
                                <template slot="header" slot-scope="scope">
                                    <div>规格货号</div>
                                    <div><el-button size="small" @click="cleanHuoHao">清空货号</el-button></div>
                                </template>
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.guiGeHuoHao" size="small"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column width="150x">
                                <template slot="header" slot-scope="scope">
                                    <div>规格条码</div>
                                    <div><el-button size="mini" @click="cleanTiaoMa">清空条码</el-button></div>
                                </template>
                                <template slot-scope="scope">
                                        <el-input v-model="scope.row.guiGeTiaoMa" size="small"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="进价" width="160x">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.id!=null && scope.row.id!=''">{{scope.row.guiGeShangPinJinJia}}</span>
                                    <el-input-number v-else v-model="scope.row.guiGeShangPinJinJia"
                                                     size="small" maxlength="8" show-word-limit
                                                     :precision="2" :step="0.01"/>
                                </template>
                            </el-table-column>
                            <el-table-column label="零售价" width="160x">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.guiGeShangPinLSJ"
                                                     size="small" maxlength="8" show-word-limit
                                                     :precision="2" :step="0.01"/>
                                </template>
                            </el-table-column>
                            <el-table-column v-if="hyjIsOpen" label="会员价" width="200px">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'guiGeShangPinList.'+scope.$index+'.huiYuanJia'" class="tableItem" :rules="rules.requiredHuiYuanJia">
                                        <el-input v-model="scope.row.huiYuanJia" type="number" placeholder="请输入会员价" size="small">
                                            <el-button size="small" slot="prepend">￥</el-button>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item v-if="isJiYuShangPin" :prop="'guiGeShangPinList.'+scope.$index+'.huiYuanJiaJF'" class="tableItem" :rules="rules.requiredHuiYuanJiaJF">
                                        <el-input v-model="scope.row.huiYuanJiaJF" placeholder="请输入积分" size="small">
                                            <el-button size="small" slot="prepend" icon="el-icon-coin"></el-button>
                                        </el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column v-if="plusHyjIsOpen" label="PLUS会员价" width="200px">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'guiGeShangPinList.'+scope.$index+'.plusHuiYuanJia'" class="tableItem" :rules="rules.requiredHuiYuanJia">
                                        <el-input v-model="scope.row.plusHuiYuanJia" placeholder="请输PLUS入会员价" size="small">
                                            <el-button size="small" slot="prepend">￥</el-button>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item v-if="isJiYuShangPin" :prop="'guiGeShangPinList.'+scope.$index+'.plusHuiYuanJiaJF'" class="tableItem" :rules="rules.requiredHuiYuanJiaJF">
                                        <el-input v-model="scope.row.plusHuiYuanJiaJF" placeholder="请输入积分" size="small">
                                            <el-button size="small" slot="prepend" icon="el-icon-coin"></el-button>
                                        </el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="库存" width="160x">
                                <template slot-scope="scope">
                                    <el-input-number @change="ggKuCunSheZhi" v-if="!isUpdate" v-model="scope.row.guiGeShangPinKuCun" size="small" :min="0" :max="1000000"></el-input-number>
                                    <span v-else>{{scope.row.guiGeShangPinKuCun}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="超量" width="160x">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.guiGeShangPinChaoLiang" size="small" :min="0" :max="1000000"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column label="预警" width="160x">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.guiGeShangPinYuJing" size="small" :min="0" :max="1000000"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column width="200px" label="操作" align="center" fixed="right">
                                <template slot-scope="scope">
                                    <el-button @click="tableDelete(scope.row)" type="danger" size="mini" slot="reference">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-form-item>
                <el-divider content-position="left">图片设置</el-divider>
                <el-form-item label="商品封面图" prop="shangPinFengMianTu" class="line">
                    <x-uploader v-model="form.shangPinFengMianTu" list-type="picture-card" accept="image/jpeg,image/png" :size="1" :on-success="uploadSuccess"></x-uploader>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="commit" icon="el-icon-document-add">保 存</el-button>
                    <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
                </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate, getItemNumber,getBarcode} from "@/service/spgl/ShangPinXX";
    import {getUpdate as dkGetUpdate} from "@/service/hygl/JiFenDiKouSheZhi"
    import {mainTree,getUpdate as getFenLeiById} from "@/service/spgl/ShangPinFenLei"
    import {getPinPaiSelectList} from "@/service/spgl/ShangPinPinPai"
    import {getDanWeiSelectList} from "@/service/spgl/ShangPinDW"
    import {selectList as getGongHuoShangSelectList} from "@/service/cggl/GongHuoShang"
    import {toFirstPinYin} from "@/util/pinyin";
    import {getGuiGeList} from "@/service/spgl/ShangPinGuiGe"
    import XEditBase from "@/components/x/XEditBase";
    import XUploader from "@/components/x/upload/XUploader";
    import {ruleBuilder} from '@/util/validate';
    import {deepCopy,deepMerge,CustomMath} from "@/util/objects"
    import {YING_XIAO_FANG_SHI,DICT_AUDIT_STATUS,DICT_TYPE_USER_ORGAN_LX,DICT_YES_OR_NO,SPXX_BATCH_SET_BALUE} from "@/util/constant"

    export default {
        mixins: [XEditBase],
        components:{XUploader},
        beforeCreate() {
            let {required,positiveEightTwo,digits} = ruleBuilder;
            this.rules = {
                shangPinMC: [required()], // 商品名称
                shangPinHuoHao: [required()], // 商品货号
                shangPinTiaoMa: [required()], // 商品条码
                shangPinKuCun: [], // 商品库存
                shangPinLeiBie: [required('string', ['blur', 'change'])], // 商品类别
                dianNeiLSJ: [required()], // 店内零售价
                shangPinDWRequired: [required('string', ['blur', 'change'])],
                huiYuanJia:[positiveEightTwo()],
                huiYuanJiaJF:[digits()],
                requiredHuiYuanJia:[required('string', ['blur', 'change']),positiveEightTwo()],
                requiredHuiYuanJiaJF:[required('string', ['blur', 'change']),digits()],
            }
            this.titlePrefix = '商品信息';
            this.defaultForm = {
                id: null,
                shangPinMC: "", // 商品名称
                shangPinHuoHao: "", // 商品货号
                shangPinTiaoMa: "", // 商品条码
                shangPinKuCun: 0, // 商品库存
                chuShiShangPinKuCun:0,//初始商品库存
                shangPinLeiBie: "", // 商品类别
                fenLeiMC:"",//分类名称
                shangPinJinJia: "", // 商品进价
                chuShiJinJia:"",//商品初始进价
                dianNeiLSJ: "", // 店内零售价
                huiYuanJia:'',//会员价
                plusHuiYuanJia:'',//plus会员价
                huiYuanJiaJF:'',//会员价积分
                plusHuiYuanJiaJF:'',//plus会员价积分
                huiYuanJiaZT:'false',//会员价状态
                plusHuiYuanJiaZT:'false',//plus会员价状态
                pinYinMa:'',//拼音码
                shangPinPinPai: "", // 商品品牌
                shangPinPinPaiMC:'',
                shangPinPaiXu: 1, // 商品排序
                shangPinChanDi: "", // 商品产地
                shangPinDW: "", // 商品单位
                gongHuoShang: '',//供货商
                gongHuoShangMC:"",
                shengChanRQ: "", // 生产日期
                guoQiRQ: "", // 过期日期
                shangPinChaoLiang: 0, // 商品超量
                baoZhiQi: "", // 保质期
                kuCunYuJing: 0, // 库存预警
                shiFuChengZhong: "false", // 是否称重
                shangPinZT: "true", // 商品状态
                shiFuDianNeiChuShou: "true", // 是否店内出售
                shiFuDianNeiChangYong: "false", // 是否店内常用
                shiFuYunXuChaiFen: "false", // 是否允许拆分
                shiFuYunXuTuiKuan: "false", // 是否允许退款
                shiFuYunXuHuanHuo: "false", // 是否允许换货
                shangPinFengMianTu: [], // 商品封面图
                shangPinXiangQing: "", // 商品详情
                guiShuMenDian: null, // 归属门店
                guiShuCangKu: "", // 归属仓库
                shiFuZengPin: "false", // 是否赠品
                zengPinKuSL: 0, // 赠品库数量
                zengPinZT:"false",//赠品状态
                zengPinChengBen:0,//赠品成本价
                shiFuDuoGuiGe: "false", // 是否多规格
                ziDongBH: "true", // 自动编号
                ziDongShengChengTiaoMa: "true", // 自动生成条码
                ziDongShengChengPLUMa: "", // 自动生成PLU码
                dingJiGuiGe: "", // 顶级规格
                ciJiGuiGe: "", // 次级规格
                ciJiGuiGeMC:"",//次级规格名称
                ciJiGuiGeShangPinTuPian: [], // 次级规格商品图片
                guiGeHuoHao: "", // 规格货号
                guiGeTiaoMa: "", // 规格条码
                guiGePLUMa: "", // 规格PLU码
                guiGeShangPinJinJia: "", // 规格商品进价
                guiGeShangPinLSJ: "", // 规格商品零售价
                guiGeShangPinKuCun: 0, // 规格商品库存
                guiGeShangPinChaoLiang: 0, // 规格商品超量
                guiGeShangPinYuJing: 0, // 规格商品预警
                shiFuShanChu: "false", // 是否删除
                shiFuLieBiaoZS:"false",//是否列表展示
                shangPinBanBenHao:"",//商品版本号
                shangPinLaiYuan:"自行添加",
                guiGeShangPinList:[],
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        data() {
            return {
                huoHaoContent: "称重商品货号建议5位数，即10000-99999，否则在电子条码秤上可能无法识别",
                paiXuContent: "商品默认排序，正序(默认为1)",
                chengZhongContent: "开启称重设置必须选择商品单位",
                chaiFenContent: "可分成小件商品出售的商品可拆分（具体小件商品和小件商品数量请到拆分商品编辑）",
                tuiKuanContent: "开启表示此商品在退款换货时间范围内允许退款",
                huanHuoContent: "开启表示此商品在退款换货时间范围内允许换货",
                guiGeContent: "规格商品以规格总库存为准，且不支持规格与称重同时开启，编辑商品不可修改规格基本属性或关闭、开启规格",
                ziDongBianHaoContent: "将根据商品货号来自动给规格商品生成货号（若不填写商品货号，则不会自动编号）",
                ziDongBianMaContent: "将根据商品条码来自动给规格商品编码（若不填写商品条码，则不会自动编码）",
                guiGeStatusContent: "可一键开启或关闭规格状态",
                fenLeiOptions: [],
                pinPaiOptions: [],
                danWeiOptions: [],
                dingJiGuiGeOptions: [],
                lowerGuiGeOptions: [],
                guiGeCheckList: [],
                gongHuoShangOptions:[],
                batchSetValue: 0.00,
                batchSetSelectValue: 1,
                tableData: [],
                tableAllStatus: "true",
                dictYesOrNo:DICT_YES_OR_NO,
                dkSheZhiObj:{},
                batchSetValueOptions:SPXX_BATCH_SET_BALUE.batchOptions,
                defaultAttribute:{
                    chengZhongDisabled: false,
                    guiGeDisabled: false,
                    menDianShangPinShow: false,
                },
                cancelAttribute:{
                    chengZhongDisabled: false,
                    guiGeDisabled: false,
                    menDianShangPinShow: false,
                },
                fenLeiProps: {
                    emitPath:false,
                    value: "id",
                    label: "name",
                    children: "subNodes",
                    checkStrictly:false,
                    multiple:false
                },
            }
        },
        mounted() {
        },
        computed:{
            isDuoGuiGe(){
                return this.form.shiFuDuoGuiGe===DICT_YES_OR_NO.YES.key
            },
            hyjIsOpen(){
                return this.form.huiYuanJiaZT===DICT_YES_OR_NO.YES.key
            },
            plusHyjIsOpen(){
                return this.form.plusHuiYuanJiaZT===DICT_YES_OR_NO.YES.key
            },
            isJiYuShangPin(){
                if(this.dkSheZhiObj){
                    return this.dkSheZhiObj.shiFuKaiQi===DICT_YES_OR_NO.YES.key
                        && this.dkSheZhiObj.yingXiaoFangShi===YING_XIAO_FANG_SHI.JYSP.key
                }else{
                    return false;
                }
            }
        },
        methods: {
            kuCunChange(val){
                if(!val){
                    this.form.shangPinKuCun = 0
                }
            },
            ggKuCunSheZhi(val){
                let allKuCun = 0;
                this.form.guiGeShangPinList.forEach(item=>{
                    if(!item.guiGeShangPinKuCun){
                        item.guiGeShangPinKuCun=0
                    }
                    allKuCun = CustomMath.add2(allKuCun,item.guiGeShangPinKuCun)
                })
                this.form.shangPinKuCun = allKuCun;
            },
            toPinYinMa(){
                this.form.pinYinMa = toFirstPinYin(this.form.shangPinMC);
            },
            uploadSuccess(){
                if(this.form.shangPinFengMianTu.length>0){
                    this.form.shangPinFengMianTu.shift()
                }
            },
            //生成货号
            async getItemNumber() {
                await getItemNumber().then((response) => {
                    this.form.shangPinHuoHao = response.data
                })
            },
            //生成编码
            async getBarCode(){
                await getBarcode().then((response)=>{
                    this.form.shangPinTiaoMa = response.data
                })
            },
            //获取分类
            getFenLeiOptions() {
                mainTree().then((response) => {
                    this.fenLeiOptions = response.data
                })
            },
            //获取积分抵扣设置信息
            getDkSheZhi(){
                dkGetUpdate().then((res)=>{
                    this.dkSheZhiObj = res.data
                })
            },
            //获取供货商列表
            getGongHuoShangOptions(){
                getGongHuoShangSelectList().then((response)=>{
                    if(response.data){
                        this.gongHuoShangOptions = response.data.filter(item=>item.use=='true')
                    }
                })
            },
            //获取商品品牌
            getPinPaiOptions() {
                getPinPaiSelectList().then((response) => {
                    this.pinPaiOptions = response.data
                })
            },
            //获取单位列表
            getDanWeiOptions() {
                getDanWeiSelectList().then((response) => {
                    this.danWeiOptions = response.data
                })
            },
            //获取商品规格
            getShangPinGuiGeOptions() {
                getGuiGeList().then((response) => {
                    this.dingJiGuiGeOptions = response.data
                })
            },
            //判断是否是门店
            isMenDian() {
                if(this.$store.getters.user.userOrganLx===DICT_TYPE_USER_ORGAN_LX.MD.key){
                    this.defaultAttribute.menDianShangPinShow = true
                }else{
                    this.defaultAttribute.menDianShangPinShow = false
                }
            },
            //称重状态改变
            chengZhongChange(val) {
                if (val === DICT_YES_OR_NO.YES.key) {
                    if(!this.isUpdate){
                        this.defaultAttribute.guiGeDisabled = true
                    }
                } else {
                    if(!this.isUpdate){
                        this.defaultAttribute.guiGeDisabled = false
                    }
                }
            },
            //是否多规格参数修改
            guiGeChange(val) {
                if (val == DICT_YES_OR_NO.YES.key) {
                    this.defaultAttribute.chengZhongDisabled = true
                } else {
                    this.defaultAttribute.chengZhongDisabled = false
                    this.lowerGuiGeOptions = []
                    this.guiGeCheckList = []
                    this.form.guiGeShangPinList=[]
                    this.form.dingJiGuiGe = ""
                }
            },
            //规格商品是否自动生成编号
            bianHaoChange(val) {
                if (val === DICT_YES_OR_NO.YES.key) {
                    if(!this.form.shangPinHuoHao){
                        this.$confirm('设置商品货号后才能自动生成规格货号，是否生成商品货号','温馨提示',{
                            confirmButtonText: '生成',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(()=>{
                            this.getItemNumber()
                            this.$message({
                                type: 'success',
                                message: '生成成功!'
                            })
                        }).catch(()=>{
                            this.form.ziDongBH = DICT_YES_OR_NO.NO.key
                        })
                    }
                    if(this.form.guiGeShangPinList && this.form.guiGeShangPinList.length>0){
                        let i =0;
                        this.form.guiGeShangPinList.forEach(item=>{
                            item.guiGeHuoHao= this.form.shangPinHuoHao.concat("-",i);
                            i++;
                        })
                    }
                } else {
                    if(this.form.guiGeShangPinList && this.form.guiGeShangPinList.length>0){
                        this.form.guiGeShangPinList.forEach(item=>item.guiGeHuoHao="")
                    }
                }
            },
            //商品类别变化时的处理
            leiBieChange(val){
                getFenLeiById(val).then((response)=>{
                    this.form.fenLeiMC = response.data.name
                })
            },
            //供货商变化时处理
            gongHuoShangChange(val){
                let gongHuoshang = this.gongHuoShangOptions.find(item=>item.id == val)
                this.form.gongHuoShangMC = gongHuoshang.gongHuoShangMC
            },
            pinPaiChange(val){
               let pinPai = this.pinPaiOptions.find(item=>item.id == val)
                this.form.shangPinPinPaiMC = pinPai.pinPaiMC
            },
            //规格商品是否自动生成条码
            tiaoMaChange(val) {
                if (val ===DICT_YES_OR_NO.YES.key) {
                    if(!this.form.shangPinTiaoMa){
                        this.$confirm('设置商品条码后才能自动生成规格条码，是否生成商品条码','温馨提示',{
                            confirmButtonText: '生成',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(()=>{
                            this.getBarCode()
                            this.$message({
                                type: 'success',
                                message: '生成成功!'
                            })
                        }).catch(()=>{
                            this.form.ziDongShengChengTiaoMa = DICT_YES_OR_NO.NO.key
                        })
                    }
                    if(this.form.guiGeShangPinList && this.form.guiGeShangPinList>0){
                        let i = 0;
                        this.form.guiGeShangPinList.forEach(item=>{
                            item.guiGeTiaoMa = this.form.shangPinTiaoMa.concat("-",i);
                            i++;
                        })
                    }
                } else {
                    if(this.form.guiGeShangPinList && this.form.guiGeShangPinList.length>0){
                        this.form.guiGeShangPinList.forEach(item=>item.guiGeTiaoMa="")
                    }
                }
            },
            //顶级规格发生变化时的处理
            guiGeSelectChange(val) {
                this.lowerGuiGeOptions = []
                this.guiGeCheckList = []
                this.form.guiGeShangPinList=[]
                let selectOption = this.dingJiGuiGeOptions.filter(item => item.id == val)
                if (selectOption && selectOption.length > 0) {
                    if(selectOption[0].list){
                        this.lowerGuiGeOptions = selectOption[0].list
                    }else{
                        this.lowerGuiGeOptions = []
                    }
                }
            },
            //次级规格变化时的处理
            lowerGuiGeChange(val) {
                let midTableData = []
                if(val.length==0){
                    this.form.guiGeShangPinList=[]
                }else{
                    let k = 0;
                    //更新数据基本信息
                    val.forEach(valItem=>{
                        let midData = {}
                        Object.assign(midData,this.form)
                        midData.guiGeShangPinList=[]
                        midData.ciJiGuiGe = valItem
                        midData.shangPinZT = DICT_YES_OR_NO.YES.key
                        midData.ciJiGuiGeMC = this.lowerGuiGeOptions.find(item=>item.id==valItem).guiGeMC
                        if(this.form.ziDongBH==DICT_YES_OR_NO.YES.key && this.form.shangPinHuoHao){
                            midData.guiGeHuoHao = this.form.shangPinHuoHao.concat("-",k)
                        }
                        if(this.form.ziDongShengChengTiaoMa==DICT_YES_OR_NO.YES.key && this.form.shangPinTiaoMa){
                            midData.guiGeTiaoMa = this.form.shangPinTiaoMa.concat("-",k)
                        }
                        midTableData.push(midData)
                        k++;
                    })
                    //旧数据的信息拷贝至新数据中
                    if(this.form.guiGeShangPinList.length>0){
                        midTableData.forEach(midItem=>{
                            this.form.guiGeShangPinList.forEach(tableItem=>{
                                if(midItem.ciJiGuiGe===tableItem.ciJiGuiGe){
                                    midItem.id=tableItem.id
                                    midItem.guiGeMC=tableItem.guiGeMC
                                    midItem.shangPinZT = tableItem.shangPinZT
                                    midItem.guiGeShangPinJinJia = tableItem.guiGeShangPinJinJia
                                    midItem.guiGeShangPinLSJ = tableItem.guiGeShangPinLSJ
                                    midItem.guiGeShangPinYuJing = tableItem.guiGeShangPinYuJing
                                    midItem.ciJiGuiGeShangPinTuPian = tableItem.ciJiGuiGeShangPinTuPian
                                    midItem.guiGeShangPinKuCun = tableItem.guiGeShangPinKuCun
                                    midItem.guiGeShangPinChaoLiang = tableItem.guiGeShangPinChaoLiang
                                    midItem.huiYuanJia=tableItem.huiYuanJia
                                    midItem.huiYuanJiaJF=tableItem.huiYuanJiaJF
                                    midItem.plusHuiYuanJia=tableItem.plusHuiYuanJia
                                    midItem.plusHuiYuanJiaJF=tableItem.plusHuiYuanJiaJF
                                }
                            })
                        })
                    }
                    this.form.guiGeShangPinList = midTableData
                }
            },
            //批量设置值的方法
            batchSetFunction() {
                if(this.batchSetSelectValue==1){
                    this.form.guiGeShangPinList.map(item=>{
                        item.guiGeShangPinJinJia = this.batchSetValue
                    })
                }else if(this.batchSetSelectValue==2){
                    this.form.guiGeShangPinList.map(item=>{
                        item.guiGeShangPinLSJ = this.batchSetValue
                    })
                }else{
                    this.form.guiGeShangPinList.map(item=>{
                        item.guiGeShangPinYuJing = this.batchSetValue
                    })
                }
            },
            //次级规格状态整体修改
            tableAllStatusChange(val) {
                this.form.guiGeShangPinList.forEach(item=>{
                    item.shangPinZT = val
                })
            },
            //清空规格货号
            cleanHuoHao(){
                this.form.guiGeShangPinList.forEach(item=>{
                    item.guiGeHuoHao=''
                })
            },
            //清空条码
            cleanTiaoMa(){
                this.form.guiGeShangPinList.forEach(item=>{
                    item.guiGeTiaoMa=''
                })
            },
            //删除表格中的数据以及选项数据
            tableDelete(row){
                let filterData = this.form.guiGeShangPinList
                this.form.guiGeShangPinList = filterData.filter(item=>item.ciJiGuiGe!=row.ciJiGuiGe)
                this.guiGeCheckList = this.guiGeCheckList.filter(item=>item!=row.ciJiGuiGe)
            },
            //关闭弹窗
            cancel(){
                this.tableAllStatus = "true"
                this.guiGeCheckList = []
                this.defaultAttribute = deepCopy(this.cancelAttribute)
                //关闭弹出框
                this.editor.visible = false;
            },
            open(){
                // this.rules.shangPinDW = []
                //打开页面，获取相关选择列表数据
                this.getFenLeiOptions()
                this.getPinPaiOptions()
                this.getDanWeiOptions()
                this.getGongHuoShangOptions()
                this.getShangPinGuiGeOptions()
                this.isMenDian()
                this.getDkSheZhi()
                //验证是编辑还是添加并处理
                if (this.isUpdate) {
                    this.batchSetValueOptions[0].disabled = true
                    this.batchSetValueOptions[1].disabled = true
                    this.batchSetSelectValue = 3
                    return this.getUpdateService(this.editor.id).then((response) => {
                        this.form = deepMerge(this.defaultForm, response.data);
                        this.defaultAttribute.guiGeDisabled = true
                        if(this.form.shiFuDuoGuiGe===DICT_YES_OR_NO.YES.key){
                            this.defaultAttribute.chengZhongDisabled = true
                            this.guiGeSelectChange(response.data.dingJiGuiGe)
                            response.data.guiGeShangPinList.forEach((item)=>this.guiGeCheckList.push(item.ciJiGuiGe))
                            this.form.guiGeShangPinList = response.data.guiGeShangPinList
                        }
                        //清空校验
                        this._clearValidate();
                        return response;
                    })
                } else {
                    this.form = deepCopy(this.defaultForm);
                    this.batchSetSelectValue = 1
                    //清空校验
                    this._clearValidate();
                    return Promise.resolve({code: 200});
                }
            },
            commit() {
                this.form.auditStatus = DICT_AUDIT_STATUS.WAIT.key;
                let commitList = []
                if(this.form.shiFuDuoGuiGe==DICT_YES_OR_NO.YES.key && this.form.guiGeShangPinList.length==0){
                    this.$message({
                        message: '请添加完善规格商品信息！',
                        type: 'warning'
                    });
                    return;
                }
                let isCommit = true
                if(this.form.shiFuDuoGuiGe==DICT_YES_OR_NO.YES.key){
                    for(let i = 0;i<this.form.guiGeShangPinList.length;i++){
                        if(!this.form.guiGeShangPinList[i].guiGeHuoHao){
                            this.$message.error('请完善规格商品货号');
                            isCommit = false;
                        }
                        if(!this.form.guiGeShangPinList[i].guiGeTiaoMa){
                            this.$message.error('请完善规格商品条码');
                            isCommit = false;
                        }
                        if(!this.form.guiGeShangPinList[i].guiGeShangPinLSJ){
                            this.$message.error('请完善规格商品零售价');
                            isCommit = false;
                        }
                        let guiGeShangPin = {}
                        Object.assign(guiGeShangPin,this.form)
                        guiGeShangPin.guiGeShangPinList=[]
                        guiGeShangPin.id = this.form.guiGeShangPinList[i].id
                        guiGeShangPin.ciJiGuiGe = this.form.guiGeShangPinList[i].ciJiGuiGe
                        guiGeShangPin.ciJiGuiGeMC = this.form.guiGeShangPinList[i].ciJiGuiGeMC
                        guiGeShangPin.shangPinZT = this.form.guiGeShangPinList[i].shangPinZT
                        guiGeShangPin.ciJiGuiGeShangPinTuPian = this.form.guiGeShangPinList[i].ciJiGuiGeShangPinTuPian
                        guiGeShangPin.guiGeHuoHao = this.form.guiGeShangPinList[i].guiGeHuoHao
                        guiGeShangPin.guiGeTiaoMa = this.form.guiGeShangPinList[i].guiGeTiaoMa
                        guiGeShangPin.guiGeShangPinJinJia = this.form.guiGeShangPinList[i].guiGeShangPinJinJia
                        guiGeShangPin.guiGeShangPinLSJ = this.form.guiGeShangPinList[i].guiGeShangPinLSJ
                        guiGeShangPin.chuShiJinJia = this.form.guiGeShangPinList[i].guiGeShangPinJinJia
                        guiGeShangPin.guiGeShangPinKuCun = this.form.guiGeShangPinList[i].guiGeShangPinKuCun
                        guiGeShangPin.guiGeShangPinChaoLiang = this.form.guiGeShangPinList[i].guiGeShangPinChaoLiang
                        guiGeShangPin.chuShiShangPinKuCun = this.form.guiGeShangPinList[i].guiGeShangPinKuCun
                        guiGeShangPin.huiYuanJia = this.form.guiGeShangPinList[i].huiYuanJia
                        guiGeShangPin.huiYuanJiaJF = this.form.guiGeShangPinList[i].huiYuanJiaJF
                        guiGeShangPin.plusHuiYuanJia = this.form.guiGeShangPinList[i].plusHuiYuanJia
                        guiGeShangPin.plusHuiYuanJiaJF = this.form.guiGeShangPinList[i].plusHuiYuanJiaJF
                        if(i==0){
                            guiGeShangPin.shiFuLieBiaoZS = DICT_YES_OR_NO.YES.key
                        }else{
                            guiGeShangPin.shiFuLieBiaoZS = DICT_YES_OR_NO.NO.key
                        }
                        commitList.push(guiGeShangPin)
                    }
                    commitList.forEach(item=>item.shangPinKuCun = this.form.shangPinKuCun)
                }else{
                    this.form.shiFuLieBiaoZS = DICT_YES_OR_NO.YES.key
                    this.form.chuShiJinJia = this.form.shangPinJinJia
                    this.form.chuShiShangPinKuCun = this.form.shangPinKuCun
                }
                if(!isCommit){
                    return;
                }
                this.form.guiGeShangPinList = commitList
                this.save();
            },
        },
    }
</script>

<style scoped>
    .newItem{
        border: 0px;
    }
    .tableItem{
        border: 0px;
        width:100%;
    }
</style>
